<template>
    <v-sheet>
        <!-- PC -->
        <v-card v-if="$vuetify.breakpoint.mdAndUp" width="500" style="margin-top:120px; margin-bottom:200px;" class="pt-10 pb-10 mx-auto">
            <p class="text-center font-weight-bold">회원가입 시 입력하셨던 휴대폰번호/이메일을 입력해주세요.</p>
            <v-sheet class="px-10 mt-6 mx-16">
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2"
                    placeholder=" 핸드폰"
                    v-model="idfind.sellerCellPhone"
                    hide-details
                    color="blue"
                    style="background:#F3FBFC !important;border: 1px solid gainsboro"
                ></v-text-field>
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2 mt-3"
                    placeholder=" 이메일"
                    v-model="idfind.email"
                    hide-details
                    color="blue"
                    style="background:#F3FBFC !important;border: 1px solid gainsboro"
                ></v-text-field>
                <v-btn @click="submit()" tile large dark depressed color="#02BDBB" block class="mt-4" style="height: 50px;">
                    아이디 찾기
                </v-btn>
                <v-btn to="/auth/login" tile dark depressed color="grey lighten-1" block class="mt-4" style="height: 50px;">
                    로그인으로 되돌아가기
                </v-btn>
            </v-sheet>
        </v-card>

        <!-- 모바일 -->
        <v-card v-if="!$vuetify.breakpoint.mdAndUp" width="100%" class="my-16 pt-10 pb-6 mx-auto" style="max-width: 450px">
            <v-sheet color="px-10">
              <p class="text-center font-weight-bold">회원가입 시 입력하셨던 휴대폰번호/이메일을 입력해주세요.</p>
            </v-sheet>
            <v-sheet class="mt-8 mx-10">
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2"
                    placeholder=" 핸드폰"
                    v-model="idfind.sellerCellPhone"
                    hide-details
                    color="blue"
                    style="background:#F3FBFC;border: 1px solid gainsboro"
                ></v-text-field>
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2 mt-3"
                    placeholder=" 이메일"
                    v-model="idfind.email"
                    hide-details
                    color="blue"
                    style="background:#F3FBFC;border: 1px solid gainsboro"
                ></v-text-field>
                <v-btn @click="submit()" tile large dark depressed color="#02BDBB" block class="mt-4" style="height: 50px;">
                    아이디 찾기
                </v-btn>
                <v-btn to="/auth/login" tile dark depressed color="grey lighten-1" block class="mt-4" style="height: 50px;">
                    로그인으로 되돌아가기
                </v-btn>
            </v-sheet>
        </v-card>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        idfind: {
            sellerCellPhone: "",
            email: "",
        }
    }),

    methods: {
        submit(){
          const DOMAIN = process.env.VUE_APP_API_HOST
          if(this.idfind.sellerCellPhone=="" || this.idfind.email==""){
            alert("핸드폰 또는 이메일이 입력되지 않았습니다.")
          }else{
            let params = {
              sellerCellPhone: this.idfind.sellerCellPhone,
              email: this.idfind.email,
            }
              this.$http.post(DOMAIN + "/api/v1/seller/find/my/id", params)
              .then((res) => {
                  if(res.data.resCode=='0000'){
                      alert("찾으시는 아이디는 입력하신 핸드폰으로 발송되었습니다.")
                  }
              }).catch(error => {
                  console.log(error.response)
                  alert("해당 핸드폰, 이메일에 해당하는 아이디가 존재하지 않습니다.")
              });
            }
        }
    }
}
</script>
<style>
.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none; 
}
</style>