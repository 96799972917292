<template>
    <v-sheet>
        <!-- PC -->
        <v-card v-if="$vuetify.breakpoint.mdAndUp" width="500" style="margin-top:120px; margin-bottom:200px;" class="pt-10 pb-10 mx-auto">
            <v-img class="mx-auto" width="225" :src="require('@/assets/login/logo.png')"></v-img>
            <v-sheet class="px-10 mt-6 mx-16">
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2"
                    placeholder=" 아이디"
                    v-model="id"
                    hide-details
                    color="blue"
                    style="background:#F3FBFC !important;border: 1px solid gainsboro"
                ></v-text-field>
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2 mt-3"
                    placeholder=" 비밀번호"
                    v-model="password"
                    hide-details
                    type="password"
                    color="blue"
                    style="background:#F3FBFC !important;border: 1px solid gainsboro"
                    @focus="passwordChanged"
                    @blur="focusOut"
                ></v-text-field>
                <v-btn @click="submit()" tile large dark depressed color="#02BDBB" block class="mt-4" style="font-size: 20px;height: 50px;font-weight:bold;"
                       :loading="loggingIn"
                >
                    로그인
                </v-btn>
                <v-sheet class="mt-1 d-flex justify-center align-center">
                    <v-btn to="/auth/find/id" text small class="px-1 mx-1">아이디 찾기</v-btn>
                    <v-divider vertical class="my-2"></v-divider>
                    <v-btn to="/auth/find/password" text small class="px-1 mx-1">비밀번호 찾기</v-btn>
                    <v-divider vertical class="my-2"></v-divider>
                    <v-btn to="/join/agree" text small class="px-1 mx-1">회원가입</v-btn>
                </v-sheet>
              <div style="width: 100%" class="d-flex justify-center align-center">
                <v-checkbox label="아이디 저장" v-model="autoLogin" @change="autoLoginChanged"></v-checkbox>
              </div>
            </v-sheet>
        </v-card>

        <!-- 모바일 -->
        <v-card v-if="!$vuetify.breakpoint.mdAndUp" width="100%" class="my-16 pt-10 pb-6 mx-auto" style="max-width: 450px">
            <v-sheet>
                <v-img width="40%" class="mx-auto" :src="require('@/assets/login/logo.png')"></v-img>
            </v-sheet>
            <v-sheet class="mt-8 mx-10">
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2"
                    placeholder=" 아이디"
                    v-model="id"
                    hide-details
                    color="blue"
                    style="background:#F3FBFC;border: 1px solid gainsboro"
                ></v-text-field>
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2 mt-3"
                    placeholder=" 비밀번호"
                    v-model="password"
                    hide-details
                    type="password"
                    color="blue"
                    style="background:#F3FBFC;border: 1px solid gainsboro"
                    @focus="passwordChanged"
                    @blur="focusOut"
                ></v-text-field>
                <v-btn @click="submit()" tile large dark depressed color="#02BDBB" block class="mt-4" :loading="loggingIn" style="font-size: 20px;height: 50px;font-weight:bold;">
                    로그인
                </v-btn>
<!--                <v-sheet class="py-3 text-center">
                    <v-btn to="/auth/find/id" class="my-2" small tile depressed block dark style="background:#e8d3d2; color:#a87675; font-weight:bold;height: 32px">아이디 찾기</v-btn>
                    <v-btn to="/auth/find/password" class="my-2" small tile depressed block dark style="background:#e8d3d2; color:#a87675; font-weight:bold;height: 32px">비밀번호 찾기</v-btn>
                    <v-btn to="/join/agree" class="my-2" small tile depressed block dark style="background:#e8d3d2; color:#a87675; font-weight:bold;height: 32px">회원가입</v-btn>
                </v-sheet>-->
                <v-sheet class="mt-1 d-flex justify-center align-center">
                  <v-btn to="/auth/find/id" text small class="px-1 mx-1">아이디 찾기</v-btn>
                  <v-divider vertical class="my-2"></v-divider>
                  <v-btn to="/auth/find/password" text small class="px-1 mx-1">비밀번호 찾기</v-btn>
                  <v-divider vertical class="my-2"></v-divider>
                  <v-btn to="/join/agree" text small class="px-1 mx-1">회원가입</v-btn>
                </v-sheet>
              <div style="width: 100%" class="d-flex justify-center align-center">
                <v-checkbox label="아이디 저장" v-model="autoLogin" @change="autoLoginChanged"></v-checkbox>
              </div>
            </v-sheet>
        </v-card>
    </v-sheet>
</template>
<script>
import { mapMutations } from 'vuex'
import {SessionToken} from "@/lib/SessionManager";
import {UrlUtils} from "@/lib/CommUtil";
import {AuthApi} from "@/api/auth";
import {AuthCypherApi} from "../../api/auth";

const {accountApi} = require("@/api/Account");
import RSAKey from '@/lib/rsa.js'

export default {
  data: () => ({
    id: "",
    password: "",
    encPassword: "",
    loggingIn:false,
    autoLogin:false,
    encMode : false,
  }),
  created() {
    // 먼저 로그인 페이지를 띄우기 전에
    if(SessionToken.isLogin() == true){
      console.log('로그인 돼 있음')
      const param = UrlUtils.getUrlParams()
      console.log(location.search)
      console.log(param)

      if(Object.prototype.hasOwnProperty.call(param, 'rurl')){
        const rurl = param.rurl
        UrlUtils.redirectWithSessionTempKey(rurl)
      }else{
        this.redirectAfterLogin()
        //this.$router.push({name:'home'})
      }
    }else{
      console.log('로그인이 안돼 있음')
    }
  },
  mounted() {
    const value = this.$cookies.get('autoLogin');
    if(value === null || value === undefined){
      this.autoLogin=false
      this.autoLoginChanged(false)
      return
    }
    this.autoLogin= value === 'true'
    if(this.autoLogin === true){
      const encAutoLoginUser = this.$cookies.get('encAutoLoginUser')
      if(encAutoLoginUser === null){
        return;
      }
      this.id=encAutoLoginUser.id
      this.password=encAutoLoginUser.password
      this.encMode=true
    }

  },
  methods: {
        ...mapMutations(['login']),
    /*submit(){
          this.loggingIn=true
          AuthApi.login(this.id,this.password)
              .then((res) => {
                this.loggingIn=false
                if( res.resCode !== '0000'){
                  alert("로그인 요청이 실패하였습니다.")
                  return
                }
                if(!res.data.authenticated){
                  alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
                }

                if(res.data.code=='00' || res.data.code=='08'){
                  SessionToken.clear()
                  this.login(res.data);
                  this.$store.commit('setLoginUrl','/auth/login')
                  this.getMyInfo()
                  accountApi.initializeSeller()
                  this.redirectAfterLogin()
                }
              }).catch(ex =>{
            this.loggingIn=false
            alert('네트워크 오류이거나 서버 오류 발생하여 인증 요청이 실패하였습니다.')
            throw ex
          })
    },*/
    async submit(){
      this.loggingIn=true
      // 자동 로그인 경우
      if(this.autoLogin === true ){
        try{
          const res = await AuthCypherApi.getKey()
          if(res.resCode ==='0000'){
            const rsa = new RSAKey()
            rsa.setPublic(res.data.rsaPublicKeyModulus, res.data.rsaPublicKeyExponent)
            this.requestEncAutoLogin(rsa.encrypt(this.id),this.password,rsa)
          }else{
            this.requestAutoLogin()
          }
        }catch (e) {
          this.requestAutoLogin()
        }
        return
      }
      // 자동 로그인 아닌 경우
      try{
        const res = await AuthCypherApi.getKey()
        if(res.resCode ==='0000'){
          const rsa = new RSAKey()
          rsa.setPublic(res.data.rsaPublicKeyModulus, res.data.rsaPublicKeyExponent)
          this.requestEncLogin(rsa.encrypt(this.id),rsa.encrypt(this.password))
        }else{
          this.requestLogin()
        }
      }catch (e) {
        this.requestLogin()
      }
      this.$cookies.remove("encAutoLoginUser");
    },
    redirectAfterLogin(){
          const param = UrlUtils.getUrlParams()
          console.log(location.search)
          console.log(param)

          if(Object.prototype.hasOwnProperty.call(param, 'rurl')){
            const rurl = param.rurl
            UrlUtils.redirectWithSessionTempKey(rurl)
          }else{
            //this.$router.push('/')
            window.location.href=process.env.VUE_APP_WEBMAIN_HOST
          }
        },
    getMyInfo(){
          accountApi.getMyInfo().then( res => {
            this.$store.commit('setMyInfo',res)
          })
    },
    autoLoginChanged(value){
      this.$cookies.set('autoLogin', value);
      if(this.encMode === true && value === false){
        this.encPassword=this.password
        this.password=''
        this.encMode=false
        return
      }
    },
    passwordChanged(){
      if(this.encMode === true){
        this.encPassword=this.password
        this.password=''
        this.encMode=false
      }
    },
    focusOut(){
      if(this.encMode === false && this.encPassword !== '' && this.password ===''){
        this.encMode=true
        this.password=this.encPassword
        this.encPassword=''
      }
    },
    requestLogin(){
      AuthApi.login(this.id,this.password)
          .then((res) => {
            this.loggingIn=false
            if( res.resCode !== '0000'){
              alert("로그인 요청이 실패하였습니다.")
              return
            }
            if(!res.data.authenticated){
              alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
            }

            if(res.data.code=='00' || res.data.code=='08'){
              SessionToken.clear()
              this.login(res.data);
              this.$store.commit('setLoginUrl','/auth/login')
              this.getMyInfo()
              accountApi.initializeSeller()
              this.redirectAfterLogin()
            }
          }).catch(ex =>{
        console.error(ex)
        this.loggingIn=false
        if(ex.status === 500){
          alert('네트워크 오류이거나 서버 오류 발생하여 인증 요청이 실패하였습니다.')
          return
        }
        alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
      })
    },
    requestEncLogin(id,password){
      AuthCypherApi.login(id,password)
          .then((res) => {
            this.loggingIn=false
            if( res.resCode !== '0000'){
              alert("로그인 요청이 실패하였습니다.")
              return
            }
            if( res.data.code === '11'){
              this.requestLogin()
              return;
            }
            if(!res.data.authenticated){
              alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
            }

            if(res.data.code=='00' || res.data.code=='08'){
              SessionToken.clear()
              this.login(res.data);
              this.$store.commit('setLoginUrl','/auth/login')
              this.getMyInfo()
              accountApi.initializeSeller()
              this.redirectAfterLogin()
            }
          }).catch(ex =>{
        console.error(ex)
        this.loggingIn=false
        if(ex.status === 500){
          alert('네트워크 오류이거나 서버 오류 발생하여 인증 요청이 실패하였습니다.')
          return
        }
        alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
      })
    },
    requestAutoLogin(){
      if(this.encMode === true){
        AuthApi.autoSecretLogin(this.id,this.password)
            .then((res) => {
              this.loggingIn=false
              if( res.resCode !== '0000'){
                alert("로그인 요청이 실패하였습니다.")
                return
              }
              if(!res.data.authenticated){
                alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
              }

              if(res.data.code=='00' || res.data.code=='08'){
                SessionToken.clear()
                this.login(res.data);
                this.$store.commit('setLoginUrl','/auth/login')
                this.getMyInfo()
                accountApi.initializeSeller()
                this.redirectAfterLogin()
              }
            }).catch(ex =>{
          console.error(ex)
          this.loggingIn=false
          if(ex.status === 500){
            alert('네트워크 오류이거나 서버 오류 발생하여 인증 요청이 실패하였습니다.')
            return
          }
          alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
        })
        return
      }
      AuthApi.autoLogin(this.id,this.password)
          .then((res) => {
            this.loggingIn=false
            if( res.resCode !== '0000'){
              alert("로그인 요청이 실패하였습니다.")
              return
            }
            if(!res.data.authenticationResult.authenticated){
              alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
            }

            if(res.data.authenticationResult.code=='00' || res.data.authenticationResult.code=='08'){
              this.$cookies.set('encAutoLoginUser',{
                id:this.id,
                password:res.data.authToken
              })
              SessionToken.clear()
              this.login(res.data.authenticationResult);
              this.$store.commit('setLoginUrl','/auth/login')
              this.getMyInfo()
              accountApi.initializeSeller()
              this.redirectAfterLogin()
            }
          }).catch(ex =>{
        console.error(ex)
        this.loggingIn=false
        if(ex.status === 500){
          alert('네트워크 오류이거나 서버 오류 발생하여 인증 요청이 실패하였습니다.')
          return
        }
        alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
      })
    },
    requestEncAutoLogin(id,password,rsa){
      if(this.encMode === true){
        AuthCypherApi.autoEncSecretLogin(id,password)
            .then((res) => {
              this.loggingIn=false
              if( res.resCode !== '0000'){
                alert("로그인 요청이 실패하였습니다.")
                return
              }

              if( res.data.code === '11'){
                this.requestAutoLogin()
                return;
              }
              if(!res.data.authenticated){
                alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
              }

              if(res.data.code=='00' || res.data.code=='08'){
                SessionToken.clear()
                this.login(res.data);
                this.$store.commit('setLoginUrl','/auth/login')
                this.getMyInfo()
                accountApi.initializeSeller()
                this.redirectAfterLogin()
              }
            }).catch(ex =>{
          console.error(ex)
          this.loggingIn=false
          if(ex.status === 500){
            alert('네트워크 오류이거나 서버 오류 발생하여 인증 요청이 실패하였습니다.')
            return
          }
          alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
        })
        return
      }
      AuthCypherApi.autoLogin(id,rsa.encrypt(password))
          .then((res) => {
            this.loggingIn=false
            if( res.resCode !== '0000'){
              alert("로그인 요청이 실패하였습니다.")
              return
            }

            if( res.data.authenticationResult.code === '11'){
              this.requestAutoLogin()
              return;
            }
            if(!res.data.authenticationResult.authenticated){
              alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
            }

            if(res.data.authenticationResult.code=='00' || res.data.authenticationResult.code=='08'){
              this.$cookies.set('encAutoLoginUser',{
                id:this.id,
                password:res.data.authToken
              })
              SessionToken.clear()
              this.login(res.data.authenticationResult);
              this.$store.commit('setLoginUrl','/auth/login')
              this.getMyInfo()
              accountApi.initializeSeller()
              this.redirectAfterLogin()
            }
          }).catch(ex =>{
        console.error(ex)
        this.loggingIn=false
        if(ex.status === 500){
          alert('네트워크 오류이거나 서버 오류 발생하여 인증 요청이 실패하였습니다.')
          return
        }
        alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
      })
    },
  },
}
</script>
<style>
.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none; 
}
</style>