<template>
    <v-sheet>
        <!-- PC -->
        <v-card v-if="$vuetify.breakpoint.mdAndUp" width="500" style="margin-top:120px; margin-bottom:200px;" class="pt-10 pb-10 mx-auto">
            <v-img class="mx-auto" width="225" :src="require('@/assets/login/logo.png')"></v-img>
            <v-sheet class="px-10 mt-6 mx-16">
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2"
                    placeholder=" 판매자 아이디"
                    v-model="sellerId"
                    hide-details
                    color="blue"
                    style="background:#F3FBFC !important;border: 1px solid gainsboro"
                ></v-text-field>
              <v-text-field
                  v-on:keyup.enter="submit"
                  class="pa-2"
                  placeholder=" 관리자 아이디"
                  v-model="id"
                  hide-details
                  color="blue"
                  style="background:#F3FBFC;border: 1px solid gainsboro"
              ></v-text-field>
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2 mt-3"
                    placeholder=" 비밀번호"
                    v-model="password"
                    hide-details
                    type="password"
                    color="blue"
                    style="background:#F3FBFC !important;border: 1px solid gainsboro"
                ></v-text-field>
                <v-btn @click="submit()" tile large dark depressed color="#02BDBB" block class="mt-4" style="font-size: 20px;height: 50px;font-weight:bold;"
                       :loading="loggingIn"
                >
                    로그인
                </v-btn>
            </v-sheet>
        </v-card>

        <!-- 모바일 -->
        <v-card v-if="!$vuetify.breakpoint.mdAndUp" width="100%" class="my-16 pt-10 pb-6 mx-auto" style="max-width: 450px">
            <v-sheet>
                <v-img width="40%" class="mx-auto" :src="require('@/assets/login/logo.png')"></v-img>
            </v-sheet>
            <v-sheet class="mt-8 mx-10">
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2"
                    placeholder=" 판매자 아이디"
                    v-model="sellerId"
                    hide-details
                    color="blue"
                    style="background:#F3FBFC;border: 1px solid gainsboro"
                ></v-text-field>
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2"
                    placeholder=" 관리자 아이디"
                    v-model="id"
                    hide-details
                    color="blue"
                    style="background:#F3FBFC;border: 1px solid gainsboro"
                ></v-text-field>
                <v-text-field
                    v-on:keyup.enter="submit"
                    class="pa-2 mt-3"
                    placeholder=" 비밀번호"
                    v-model="password"
                    hide-details
                    type="password"
                    color="blue"
                    style="background:#F3FBFC;border: 1px solid gainsboro"
                ></v-text-field>
                <v-btn @click="submit()" tile large dark depressed color="#02BDBB" block class="mt-4" :loading="loggingIn" style="font-size: 20px;height: 50px;font-weight:bold;">
                    로그인
                </v-btn>
            </v-sheet>
        </v-card>
    </v-sheet>
</template>
<script>
import { mapMutations } from 'vuex'
import {SessionToken} from "@/lib/SessionManager";
import {UrlUtils} from "@/lib/CommUtil";
import {AuthApi} from "@/api/auth";

const {accountApi} = require("@/api/Account");

export default {
  data: () => ({
    sellerId:"",
    id: "",
    password: "",
    loggingIn:false,
  }),
  created() {
    // 먼저 로그인 페이지를 띄우기 전에
    if(SessionToken.isLogin() == true){
      console.log('로그인 돼 있음')
      const param = UrlUtils.getUrlParams()
      console.log(location.search)
      console.log(param)

      if(Object.prototype.hasOwnProperty.call(param, 'rurl')){
        const rurl = param.rurl
        UrlUtils.redirectWithSessionTempKey(rurl)
      }else{
        //this.$router.push({name:'home'})
        this.redirectAfterLogin()
      }

    }else{
      console.log('로그인이 안돼 있음')
    }
  },
  methods: {
        ...mapMutations(['login']),
        submit(){
          this.loggingIn=true
          AuthApi.loginAdmin(this.sellerId, this.id,this.password)
              .then((res) => {
                this.loggingIn=false
                if( res.resCode !== '0000'){
                  alert("로그인 요청이 실패하였습니다.")
                  return
                }
                if(!res.data.authenticated){
                  alert("아이디 혹은 비밀번호가 올바르지 않습니다.")
                }

                if(res.data.code=='00' || res.data.code=='08'){
                  SessionToken.clear()
                  this.login(res.data);
                  this.getMyInfo()
                  this.$store.commit('setLoginUrl','/auth/admin/login')
                  accountApi.initializeSeller()
                  this.redirectAfterLogin()
                }
              }).catch(ex =>{
            this.loggingIn=false
            alert('네트워크 오류이거나 서버 오류 발생하여 인증 요청이 실패하였습니다.')
            throw ex
          })
        },
        redirectAfterLogin(){
          const param = UrlUtils.getUrlParams()
          console.log(location.search)
          console.log(param)

          if(Object.prototype.hasOwnProperty.call(param, 'rurl')){
            const rurl = param.rurl
            UrlUtils.redirectWithSessionTempKey(rurl)
          }else{
            this.$router.push('/')
          }
        },
        getMyInfo(){
          accountApi.getMyInfo().then( res => {
            this.$store.commit('setMyInfo',res)
          })
        }
    },
}
</script>
<style>
.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none; 
}
</style>